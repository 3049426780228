<template>
  <ion-grid>
    <ion-row
      class="ion-text-center ion-align-items-center min_full_height"
      v-if="!data.breakForm"
    >
      <ion-col>
        <count-down v-if="computedTime"
          class="countdown"
          :time="computedTime"
          @timeCompleted="timeCompleted($event)"
        ></count-down>
      </ion-col>
    </ion-row>

    <ion-row
      class="ion-text-center ion-align-items-center"
      v-if="data.breakForm"
    >
      <ion-col>
        <count-down v-if="computedTime"
          class="countdown-small"
          :time="computedTime"
          @timeCompleted="timeCompleted($event)"
        ></count-down>
      </ion-col>
    </ion-row>
  </ion-grid>

  <back-logos></back-logos>
</template>

<script>
import { defineComponent } from "vue";
import BackLogos from "@/components/BackLogos";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import CountDown from "@/components/CountDown";

export default defineComponent({
  name: "WaitTime",
  components: {
    BackLogos,
    CountDown,
  },
  mixins: [QuestionMixin, IonComponentsMixin],
  props: {
    data: Object,
  },
  data() {
    return {
      computedTime: false,
    };
  },
  methods: {
    init: async function (current_question_index) {
      this.questionIndex = current_question_index;
      this.computedTime = await this.data.getTime();
      this.$forceUpdate();
    },
    timeCompleted: async function () {
      await this.next();
    },
  },
});
</script>

<style scoped>
.countdown {
  font-size: 8rem;
}

.countdown-small {
  font-size: 3rem;
}
</style>
